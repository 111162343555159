import * as BrowserSentry from '@sentry/browser';
import { browserTracingIntegration, replayIntegration } from '@sentry/react';
import { getApiVersion } from './getApiVersion';

type SentryConfig = {
  dsn: string;
  debug: boolean;
  tracesSampleRate: number;
  replaysSessionSampleRate: number;
  replaysOnErrorSampleRate: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  integrations?: any[];
};

const sentryConfig: SentryConfig = {
  dsn: import.meta.env.VITE_SENTRY_DSN ?? '',
  debug: true,
  tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE
    ? parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE)
    : 0.5,
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,
};

const replayConfig = {
  maskAllText: false,
  blockAllMedia: false,
};

const userAgent = navigator.userAgent.toLowerCase();

export async function initSentry() {
  const apiVersion = await getApiVersion();

  if (!sentryConfig.dsn) return;

  try {
    if (userAgent.indexOf(' electron/') === -1) {
      sentryConfig.integrations = [
        browserTracingIntegration(),
        replayIntegration(replayConfig),
      ];
      BrowserSentry.init({
        ...sentryConfig,
      });

      BrowserSentry.getCurrentScope().setTag('apiversion', apiVersion);
    }
  } catch (error) {
    console.error('Error initializing Sentry (client):', error);
  }
}
