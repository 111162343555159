/* eslint-disable no-console */
import React from 'react';
import { createRoot } from 'react-dom/client';
import packagejson from '../package.json';
import Root from './Root';
import isDev from './features/common/utils/isDev';
import './index.css';
import { initSentry } from './initSentry';
// On page load or when changing themes, best to add inline in `head` to avoid FOUC

// Call the initSentry function to initialize Sentry - prod only
if (!isDev) {
  initSentry();
}

const themeSetInLocalStorage = localStorage['interface.theme'] ?? null;

try {
  if (!('interface.theme' in localStorage)) {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.classList.add('dark');
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.classList.add('light');
      document.documentElement.setAttribute('data-theme', 'light');
    }
  } else {
    if (JSON.parse(themeSetInLocalStorage) === 'dark') {
      document.documentElement.classList.add('dark');
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.classList.add('light');
      document.documentElement.setAttribute('data-theme', 'light');
    }
  }
} catch {
  document.documentElement.classList.add('light');
  document.documentElement.setAttribute('data-theme', 'light');
}

document.title = 'ONE RACEHUB Version ' + packagejson.version;
createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

if (isDev()) {
  // REGISTER ERROR OVERLAY
  const showErrorOverlay = (err: ErrorEvent) => {
    // must be within function call because that's when the element is defined for sure.
    const ErrorOverlay = customElements.get('vite-error-overlay');
    // don't open outside vite environment
    if (!ErrorOverlay) {
      return;
    }
    // eslint-disable-next-line no-console
    console.log(err);
    const overlay = new ErrorOverlay(err);
    if (
      err.message === 'ResizeObserver loop limit exceeded' ||
      err.message ===
        'ResizeObserver loop completed with undelivered notifications.'
    ) {
      return;
    } else {
      document.body.appendChild(overlay);
    }
  };

  window.addEventListener('error', showErrorOverlay);
  window.addEventListener('unhandledrejection', ({ reason }) =>
    showErrorOverlay(reason)
  );
}
